import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
import {saveFile} from "@/helpers/mediaHelper";

export class AccountService{
    formDataAccount = {};
    constructor() {
        this.formDataAccount = {};
    }

    async loadCaOnes(){
        let caOnes = Vue.prototype.$store.getters['common/caOnes'];
        if(caOnes.length <= 0){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.ca.caOnes)
                .then((response) => {
                    Vue.prototype.$store.dispatch('common/setCaOnes', {value: response.data});
                    return {caOnes: response.data};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {caOnes: []};
                })
        }
        return {caOnes: caOnes};
    }

    async loadCaTwos(){
        let caTwos = Vue.prototype.$store.getters['common/caTwos'];
        if(caTwos.length <= 0){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.ca.caTwos)
                .then((response) => {
                    Vue.prototype.$store.dispatch('common/setCaTwos', {value: response.data});
                    return {caTwos: response.data};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {caTwos: []};
                })
        }
        return {caTwos: caTwos};
    }

    async loadCaThrees(){
        let caThrees = Vue.prototype.$store.getters['common/caThrees'];
        if(caThrees.length <= 0){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.ca.caThrees)
                .then((response) => {
                    Vue.prototype.$store.dispatch('common/setCaThrees', {value: response.data});
                    return {caThrees: response.data};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {caThrees: []};
                })
        }
        return {caThrees: caThrees};
    }

    async loadCaWithAccounts(fetch){
        let caWithAccounts = Vue.prototype.$store.getters['common/caWithAccounts'];
        if(caWithAccounts.length <= 0 || fetch){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.ca.caWithAccounts)
                .then((response) => {
                    caWithAccounts = response.data;
                    Vue.prototype.$store.dispatch('common/caWithAccounts', {value: caWithAccounts});
                    return {cas: caWithAccounts};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {cas: []};
                });
        }
        return {cas: caWithAccounts};
    }
    async getAccount(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.account.account, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, account: response.data.account};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', account: null};
            });
    }
    async saveAccount(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.account.accountSave, this.formDataAccount).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.account.accountSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', account: null};
                }
            }
            await this.loadCaWithAccounts(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, account: response.data.account};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', account: null};
        });
    }

    async getDefaultCashAccount(fetch){
        let storeDefaultCashAccount = Vue.prototype.$store.getters['common/defaultCashAccount'];
        if(!storeDefaultCashAccount || fetch){
            let searchResponse = await this.searchAccount({ca_three_id: 1, limit: 1});
            if(searchResponse.accounts.length > 0){
                Vue.prototype.$store.dispatch('common/setDefaultCashAccount', {value: searchResponse.accounts[0]});
                return {isSuccess: true, message: '', accounts: searchResponse.accounts};
            }else{
                return {isSuccess: false, message: 'Something went wrong!', accounts: []};
            }
        }
        return {isSuccess: true, message: '', accounts: [storeDefaultCashAccount]};
    }
    async searchAccount(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.account.accounts, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, accounts: response.data.accounts};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', accounts: []};
            });
    }

    async delete(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.account.accountDelete, {
            id: id
        })
            .then(async (response) => {
                await this.loadCaWithAccounts(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    async searchAggregatedAccount(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.account.searchAggregatedAccounts, filters)
            .then((response) => {
                return {accounts: response.data.accounts};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {accounts: []};
            });
    }
}