<template>
    <button :class="classes" @click="click(data)" :title="meta.title? meta.title : meta.label" v-show="meta.editableCheck? data.editable: true">
        <i :class="meta.iconClass" v-if="meta.iconClass"></i>{{ meta.iconClass && meta.showLabel? ' ': '' + (meta.showLabel? meta.label: '') }}
    </button>
</template>

<script>
    export default {
        name: "CustomDataTableButton",
        props: {
            data: {},
            click: {
                type: Function,
                default: () => {}
            },
            classes: {
                type: Object,
                default: () => ({
                    'btn': true,
                    'btn-primary': true,
                    'btn-sm': true,
                }),
            },
            meta: {
                type: Object,
                default: () => ({
                    label: 'Button',
                    showLabel: true,
                    iconClass: 'fas fa-eye',
                    editableCheck: false
                })
            }
        }
    }
</script>

<style scoped>

</style>