<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-sm-3">
                        <div class="card card-secondary">
                            <div class="card-header">
                                <h3 class="card-title">{{ isSale || isSaleReturn? 'Sales' + (isSaleReturn? ' Return': ''): 'Purchase' + (isPurchaseReturn? ' Return': '') }} Information</h3>
                            </div>
                            <div class="card-body">
                                <ul class="list-group">
                                    <li class="list-group-item d-flex justify-content-between">ID: <span>{{ $route.params.id }}</span></li>
                                    <li class="list-group-item d-flex justify-content-between">Type: <span>{{ isSale || isSaleReturn? 'Sales' + (isSaleReturn? ' Return': ''): 'Purchase' + (isPurchaseReturn? ' Return': '') }}</span> </li>
                                    <li class="list-group-item d-flex justify-content-between">Date: <span>{{ invoice.date }}</span></li>
                                    <li class="list-group-item d-flex justify-content-between">Amount: <span>{{ getCurrencyFormattedVal(invoice.grand_total) }}</span></li>
                                    <li class="list-group-item d-flex justify-content-between">Paid: <span>{{ getCurrencyFormattedVal(payments.totals.amount) }}</span></li>
                                    <li class="list-group-item d-flex justify-content-between">Due: <span>{{ getCurrencyFormattedVal(due) }}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="card card-secondary" v-if="payment.showForm">
                            <div class="card-header">
                                <h3 class="card-title">Make Payment</h3>
                            </div>
                            <ValidationObserver v-slot="{ invalid }">
                                <form @submit="saveForm">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group col-sm-3">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="payment_method_id">Payment Method *</label>
                                                    <select class="form-control" id="payment_method_id" v-model="payment.formData.payment_method_id" required>
                                                        <option v-for="paymentMethod in payment.paymentMethods" :key="paymentMethod.id" :value="paymentMethod.id">{{ paymentMethod.name }}</option>
                                                    </select>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-sm-3">
                                                <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                                        <label for="cheque_no">Cheque No *</label>
                                                        <input type="text" class="form-control" id="cheque_no" placeholder="Enter the cheque number" v-model="payment.formData.cheque_no" required>
                                                        <span>{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                                <div v-if="selectedPaymentMethod && (!selectedPaymentMethod.isCheque && !selectedPaymentMethod.isCash)">
                                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                                        <div class="form-group">
                                                            <label for="other_description">Description *</label>
                                                            <input type="text" class="form-control" id="other_description" placeholder="Enter details" v-model="payment.formData.description" required>
                                                        </div>
                                                        <span>{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                                        <label for="cheque_date">Cheque Date *</label>
                                                        <input type="date" class="form-control" id="cheque_date" placeholder="Enter the cheque date" v-model="payment.formData.cheque_date" required>
                                                        <span>{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                                        <label for="cheque_bank">Cheque Bank *</label>
                                                        <input type="text" class="form-control" id="cheque_bank" placeholder="Enter the cheque bank" v-model="payment.formData.cheque_bank" required>
                                                        <span>{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="form-group col-sm-4">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="payment_date">Date *</label>
                                                    <input type="datetime-local" class="form-control" id="payment_date" placeholder="Select date of payment" v-model="payment.formData.payment_date" required>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-2">
                                                <label for="ref">Reference</label>
                                                <input type="text" class="form-control" id="ref" placeholder="Enter a reference number" v-model="payment.formData.ref">
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <label>Account *</label>
                                                <vSelect @search="searchAccount" :filterable="false" :clearable="false" :options="payment.searchedAccounts" v-model="payment.account" v-on:option:selected ="setAccount">
                                                    <template v-slot:option="option">
                                                        {{ option.label }}
                                                        <div class="text-sm">{{ option.type }}</div>
                                                    </template>
                                                    <template v-slot:no-options="{ search, searching }">
                                                        <template v-if="searching">
                                                            No results found for <em>{{ search }}</em>.
                                                        </template>
                                                        <em style="opacity: 0.5;" v-else>Start typing to search.</em>
                                                    </template>
                                                </vSelect>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                    <label for="to_amount">Amount *</label>
                                                    <input type="text" class="form-control" id="to_amount" placeholder="Enter an amount" v-model="payment.formData.amount">
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <div class="row">
                                                    <div :class="payment.formData.image? 'col-sm-6': 'col-sm-12'">
                                                        <label for="upload_image">Image</label>
                                                        <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                                    </div>
                                                    <div class="col-sm-6" v-if="payment.formData.image">
                                                        <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + payment.formData.image" v-if="payment.formData.image" />
                                                        <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" class="btn btn-sm btn-secondary" v-on:click="hideForm">Cancel</button>
                                        <button type="submit" class="btn btn-sm btn-primary float-right" v-if="saveBtnEnabled" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                                    </div>
                                </form>

                            </ValidationObserver>
                        </div>
                        <div class="card card-secondary">
                            <div class="card-header">
                                <h3 class="card-title">Payments</h3>
                                <button type="button" class="btn btn-outline-light btn-sm float-right" @click="add"><i class="fas fa-plus"></i> Add</button>
                            </div>
                            <div class="card-body">
                                <CustomDataTable v-bind:tableDataUrl="payments.tableDataUrl" v-bind:columns="payments.columns" v-bind:searchFilters="payments.searchFilters" v-bind:showSearch="payments.showSearch" v-bind:additionalButtons="payments.additionalButtons" v-bind:total-columns="payments.totalColumns" v-on:totals-calculated="payments.totals = $event" ref="mainDataTable" v-if="payments.searchFilters.invoiceNo"></CustomDataTable>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
    import Vue from 'vue'
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import CustomDataTable from "../../../components/app/table/CustomDataTable";
    import CustomDataTableButton from "../../../components/app/table/CustomDataTableButton";

    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    dayjs.extend(customParseFormat);

    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css';

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';
    import {getPermissionByKey} from "@/helpers/userHelper";
    import { getCurrencyFormattedVal} from "@/helpers/currencyHelper";

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('positiveFPN', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return value >= 0 && regexFloatingPoint.test(value)? true: 'This field must be a positive number';
    });
    import {PaymentService} from "@/services/payment-service";
    import {SaleService} from "@/services/sale-service";
    import {PurchaseService} from "@/services/purchase-service";
    import {AccountService} from "@/services/account-service";
    import {getDateTimeToday, getDateToday, convertUTCToLocalWithFormat, convertLocalToUTC} from "@/helpers/dateHelper";

    let paymentService = new PaymentService();
    let saleService = new SaleService();
    let purchaseService = new PurchaseService();
    let accountService = new AccountService();
    export default {
        name: 'PaymentsInvoice_view',
        data: function(){
            return {
                pageName: 'Invoice Payments',
                segments: [
                    { link: true, text: this.$route.params.type === this.$globalEnums.paymentTypes.Sale? 'Sales': 'Purchases', routeName: this.$route.params.type === this.$globalEnums.paymentTypes.Sale? 'App.Sales': 'App.Purchases' },
                    { link: false, text: 'Invoice Payment' },
                ],
                invoice: {},
                payment: {
                    showForm: false,
                    entity_id: null,
                    entity_label: null,
                    entity_type: null,

                    searchedAccounts: [],
                    account: null,
                    account_id: null,
                    account_label: null,
                    account_type: null,

                    paymentMethods: [],
                    upload_image: null,
                    formData: {
                        id: 0,
                        amount: null,
                        payment_date: dayjs().format('YYYY-MM-DD HH:mm:ss') ,
                        ref: null,
                        invoice_no: null,
                        type: null,
                        payment_method_id: null,
                        cheque_no: null,
                        cheque_date: getDateToday(),
                        cheque_bank: null,
                        description: null,
                        image: null,
                        store_id: null,
                        payment_transactions: [],
                    },
                    editPaymentInitAmount: 0,
                    loadedPaymentTransactions: [],
                },
                payments: {
                    tableDataUrl: this.$globalSettings.api.endpoints.payment.paymentsDatatable,
                        columns: [
                        {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                        {label: 'Date', name: 'payment_date', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name]) },
                        {label: 'Amount', name: 'amount', orderable: true},
                        {label: 'Type', name: 'type', orderable: true, transform: ({data, name}) => Object.keys(this.$globalEnums.paymentTypes).find(key => this.$globalEnums.paymentTypes[key] === data[name]) },
                        {label: 'Ref#', name: 'ref', orderable: true},
                        {label: 'Invoice#', name: 'invoice_no', orderable: true},
                        {label: 'Method', name: 'payment_method_name', orderable: true},

                        {label: '', orderable: false, classes: {'btn': true, 'btn-primary': true, 'btn-sm': true,}, style: 'width: 62px', event: "click", handler: this.edit, meta: { label: 'Edit', showLabel: false, iconClass: 'fas fa-edit', editableCheck: false }, component: getPermissionByKey(this.$globalSettings.permissions.payment.payment_edit)? CustomDataTableButton: null },
                        {label: '', orderable: false, classes: {'btn': true, 'btn-danger': true, 'btn-sm': true,}, style: 'width: 62px', event: "click", handler: this.delete, meta: { label: 'Delete', showLabel: false, iconClass: 'fas fa-trash', editableCheck: false }, component: getPermissionByKey(this.$globalSettings.permissions.payment.payment_edit)? CustomDataTableButton: null }
                    ],
                    searchFilters:{
                        search: '',
                        length: 10,
                        column: 'payment_date',
                        dir: 'desc',
                        invoiceNo: null,
                        type: null,
                    },
                    showSearch: false,
                    additionalButtons: [],
                    totalColumns: ['amount'],
                    totals: {}
                },
            }
        },
        props: {},
        components: {
            BreadCrumb, CustomDataTable, vSelect, ValidationObserver, ValidationProvider
        },
        computed: {
            isSale: function(){
                return this.$route.params.type === this.$globalEnums.paymentTypes.Sale;
            },
            isSaleReturn: function(){
                //console.log(this.$route.params.type === this.$globalEnums.paymentTypes.SalesReturn);
                return this.$route.params.type === this.$globalEnums.paymentTypes.SalesReturn;
            },
            isPurchase: function(){
                return this.$route.params.type === this.$globalEnums.paymentTypes.Purchase;
            },
            isPurchaseReturn: function(){
                return this.$route.params.type === this.$globalEnums.paymentTypes.PurchaseReturn;
            },
            selectedPaymentMethod: function () {
                return this.payment.paymentMethods.find(x => x.id === this.payment.formData.payment_method_id);
            },
            saveBtnEnabled: function () {
                return this.payment.formData.payment_method_id && this.payment.formData.payment_date && this.payment.entity_id && this.payment.formData.amount && (this.payment.formData.id === 0? this.payment.formData.amount <= this.due: (this.payment.formData.amount >= this.payment.editPaymentInitAmount? this.payment.formData.amount - this.payment.editPaymentInitAmount <= this.due: true ));
            },
            due: function () {
                return this.invoice.grand_total - this.payments.totals.amount;
            },
        },
        methods:{
            getCurrencyFormattedVal,
            hideForm: function(){
                this.payment.showForm = false;
            },
            loadPaymentMethods: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await paymentService.getPaymentMethods(false);
                if (response.isSuccess) {
                    this.payment.paymentMethods = response.paymentMethods;
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: this.isSale || this.isSaleReturn ? 'App.Sales' : 'App.Purchases'});
                }
                loader.hide();
            },
            async loadInvoiceData() {
                let loader = this.$loading.show();
                let filters = {id: this.$route.params.id, mode: 'view'};
                let response = this.isSale || this.isSaleReturn ? await saleService.getSale(filters): await purchaseService.getPurchase(filters);
                if (response.isSuccess) {
                    if (this.isSale || this.isSaleReturn) {
                        this.invoice = response.sale;
                        this.payment.entity_id = response.sale.customer.id;
                        this.payment.entity_label = response.sale.customer.name;
                        this.payment.entity_type = 'customer';
                        this.payment.formData.store_id = response.sale.store_id;
                        this.payment.formData.amount = response.sale.grand_total;
                    } else {
                        this.invoice = response.purchase;
                        this.payment.entity_id = response.purchase.supplier.id;
                        this.payment.entity_label = response.purchase.supplier.name;
                        this.payment.entity_type = 'supplier';
                        this.payment.formData.store_id = response.purchase.store_id;
                        this.payment.formData.amount = response.purchase.grand_total;
                    }
                    this.invoice.date = convertUTCToLocalWithFormat(this.invoice.date);

                    await this.loadDefaultPaymentAccount();
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: this.isSale || this.isSaleReturn ? 'App.Sales' : 'App.Purchases'});
                }
                loader.hide();
            },

            loadDefaultPaymentAccount: async function () {
                let loader = this.$loading.show();
                let response = await accountService.searchAggregatedAccount({
                    type: this.$route.params.type,
                    section: this.isSale || this.isPurchaseReturn ? 'to' : 'from'
                });
                this.payment.searchedAccounts = response.accounts;
                this.payment.account = this.payment.searchedAccounts[0];
                this.setAccount(this.payment.searchedAccounts[0]);
                loader.hide();
            },
            searchAccount: async function (search, loading) {
                if (search.length) {
                    loading(true);
                    let response = await accountService.searchAggregatedAccount({
                        keyword: search,
                        type: this.$route.params.type,
                        section: this.isSale || this.isPurchaseReturn ? 'to' : 'from'
                    });
                    this.payment.searchedAccounts = response.accounts;
                    loading(false);
                }
            },
            setAccount: function(account){
                this.payment.account_id = account.account_id;
                this.payment.account_label = account.label;
                this.payment.account_type =  account.type;
            },
            setUploadFile(){
                this.upload_image = this.$refs.upload_image.files[0];
            },
            async saveForm(e) {
                e.preventDefault();

                let loader = this.$loading.show();

                let new_payment_transaction = {
                    amount: this.payment.formData.amount,

                    from_id: this.isSale || this.isPurchaseReturn? this.payment.entity_id: this.payment.account_id,
                    from_type: this.isSale || this.isPurchaseReturn? this.payment.entity_type: this.payment.account_type,
                    from_description: null,
                    from_name: this.isSale || this.isPurchaseReturn? this.payment.entity_label: this.payment.account_label,

                    to_id: this.isPurchase || this.isSaleReturn? this.payment.entity_id: this.payment.account_id,
                    to_type: this.isPurchase || this.isSaleReturn? this.payment.entity_type: this.payment.account_type,
                    to_description: null,
                    to_name: this.isPurchase || this.isSaleReturn? this.payment.entity_label: this.payment.account_label,
                };
                //console.log(`${new_payment_transaction.from_type} ${new_payment_transaction.from_id} ${new_payment_transaction.from_name} => ${new_payment_transaction.to_type} ${new_payment_transaction.to_id} ${new_payment_transaction.to_name}`);
                this.payment.formData.payment_transactions.push(new_payment_transaction);

                this.payment.formData.payment_date = convertLocalToUTC(this.payment.formData.payment_date);

                paymentService.formDataPayment = this.payment.formData;
                //console.log(this.payment.formData.type);
                let response = await paymentService.savePayment(this.upload_image);
                if (response.isSuccess) {
                    this.payment.showForm = false;
                    await this.$refs.mainDataTable.loadTable();
                    Vue.$toast.open({message: response.message, type: 'success'});
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            unsetUploadFile(){
                if(confirm("Are you sure?")) {
                    this.payment.formData.image = null;
                }
            },

            add(){
                this.payment.formData.id = 0;
                this.payment.formData.amount = this.due;
                this.payment.formData.payment_date = getDateTimeToday();
                this.payment.formData.ref = null;
                this.payment.formData.payment_method_id = this.payment.paymentMethods.length > 0? this.payment.paymentMethods[0].id: null;
                this.payment.formData.cheque_no = null;
                this.payment.formData.cheque_date = getDateToday();
                this.payment.formData.cheque_bank = null;
                this.payment.formData.description = null;
                this.payment.formData.image = null;
                this.payment.formData.payment_transactions = [];

                this.payment.showForm = true;
            },
            edit(data){
                this.payment.formData = {
                    id: data.id,
                    amount: data.amount,
                    payment_date: dayjs(data.payment_date, "YYYY-MM-DD H:mm:ss").format('YYYY-MM-DD'),
                    ref: data.ref,
                    invoice_no: data.invoice_no,
                    type: data.type,
                    payment_method_id: data.payment_method_id,
                    cheque_no: data.cheque_no,
                    cheque_date: dayjs(data.cheque_date, "YYYY-MM-DD H:mm:ss").format('YYYY-MM-DD'),
                    cheque_bank: data.cheque_bank,
                    description: data.description,
                    image: data.image,
                    payment_transactions: [],
                };
                this.payment.editPaymentInitAmount = data.amount;

                let first_row = data.payment_transactions[0];
                this.payment.account = { id: first_row.to_id, label: first_row.to_name, type: first_row.to_type} ;
                this.payment.searchedAccounts.push = this.payment.account;
                this.payment.account_id = first_row.to_id;
                this.payment.account_type = first_row.to_type;
                this.payment.account_label = first_row.to_name;

                this.payment.entity_id = first_row.to_id;
                this.payment.entity_type = first_row.to_type;
                //this.payment.entity_label = first_row.to_name; already loaded by invoice on view load
                this.payment.showForm = true;
                //console.log(this.payment.account);
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this payment?")) {
                    this.payment.showForm = false;
                    let loader = this.$loading.show();
                    let response = await paymentService.delete({
                        id: data.id
                    });
                    if (response.isSuccess) {
                        await this.$refs.mainDataTable.loadTable();
                        Vue.$toast.open({message: response.message, type: 'success'});
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            }
        },
        created(){
            console.log(this.$route.params.type);
        },
        async mounted() {
            if (this.$route.params.id && this.$route.params.type) {
                this.payments.searchFilters.invoiceNo = this.$route.params.id;
                this.payments.searchFilters.type = this.$route.params.type;

                this.payment.formData.invoice_no = this.$route.params.id;
                this.payment.formData.type = this.$route.params.type;

                await this.loadInvoiceData();

                if (getPermissionByKey(this.$globalSettings.permissions.payment.payment_create) && getPermissionByKey(this.$globalSettings.permissions.paymentMethod.paymentMethod_list)) {
                    await this.loadPaymentMethods();
                }
            } else {
                Vue.$toast.open({message: 'Invalid Invoice ID', type: 'error'});
                await this.$router.push({name: this.isSale || this.isSaleReturn ? 'App.Sales' : 'App.Purchases'});
            }
        }
    }
</script>

<style scoped>

</style>
